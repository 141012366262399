import {
  Button,
  Card,
  Col,
  message,
  Row,
  Table,
  Input,
  Modal,
  Select,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { strings } from "res";
import { fetchAllSuara, deleteSuara } from "redux/features/suara";
import { capitalize, filter } from "lodash";
import { getUserProfile } from "redux/features/auth";

const LocalizedModal = () => {
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const hideModal = () => {
    setOpen(false);
  };
  return (
    <>
      <Modal
        title="Modal"
        open={open}
        onOk={hideModal}
        onCancel={hideModal}
        okText="Oke"
        cancelText="Cancel"
      >
        <p>Bla bla ...</p>
        <p>Bla bla ...</p>
        <p>Bla bla ...</p>
      </Modal>
    </>
  );
};

export const CONTENTS = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [metaData, setMetaData] = useState({});
  const [kabupaten, setKabupaten] = useState("");
  const [totalData, setTotalData] = useState(0);
  const [modal, contextHolder] = Modal.useModal();
  

  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sort: "created_at",
    total_data: 0,
  });

  const tableColumns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Calon",
      dataIndex: "calon",
      key: "calon",
    },
    {
      title: "Tipe",
      dataIndex: "tipe",
      key: "tipe",
    },
    {
      title: "Kabupaten",
      dataIndex: "kabupaten",
      key: "kabupaten",
    },
    {
      title: "Kecamatan",
      dataIndex: "kecamatan",
      key: "kecamatan",
    },
    {
      title: "Suara",
      dataIndex: "suara",
      key: "suara",
    },
    {
      title: () => <div className="text-center">Detail</div>,
      key: "detail",
      render: (_, record) => (
        <div className="text-center">
          <Button
            type="primary"
            style={{ textAlign: "center" }}
            onClick={() => {
              history.push({
                pathname: `${strings.navigation.path.detail_suara}`,
                state: record,
              });
            }}
          >
            Update Suara
          </Button>
        </div>
      ),
    }
  ];

  const getProfile = async (params) => {
    try {
      setLoading(true);
      const response = await dispatch(getUserProfile(params)).unwrap();
      getData({
        ...params,
        kabupaten:response.data.user.perusahaan
      });
    } catch (error) {
      setLoading(false);
      message.error(error?.message || "Failed to fetch data");
    }
  };

  const getData = async (params) => {
    try {
      setLoading(true);
      const response = await dispatch(fetchAllSuara(params)).unwrap();
      setMetaData(response.meta.page);
      setData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error?.message || "Failed to fetch data");
    }
  };

  const handleSearchByKecamatan = (event) => {
    setFilters({ ...filters, kecamatan: event.target.value ? event.target.value : undefined });
  }

  const handleTableChange = (pagination, filter, sorter) => {
    let sorterKey = "";
    setFilters({ ...filters, page: pagination.current });

    if (sorter.field === "date_published" && sorter.order) {
      sorterKey = sorter.order === "ascend" ? sorter.field : `-${sorter.field}`;
      setFilters({ ...filters, page: 1, sort: sorterKey, page: pagination.current });
    }

    if (sorter.field === "created_at" && sorter.order) {
      sorterKey = sorter.order === "ascend" ? sorter.field : `-${sorter.field}`;
      setFilters({ ...filters, page: 1, sort: sorterKey, page: pagination.current });
    }

  };

  useEffect(() => {
    // getData(filters)
    getProfile(filters)
  }, [filters]);

  return (
    <>
      <LocalizedModal></LocalizedModal>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Total Suara</h2>
          <p>Semua suara calon berdasarkan wilayah</p>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card>
            <Row gutter={[16, 2]}>
              <Col xs={24} sm={24} md={24} lg={24} span={6}>
                <Input
                  placeholder="Cari Berdasarkan Kecamatan"
                  onSelect={handleSearchByKecamatan}
                  className="mb-4 mr-4"
                  allowClear
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
            <Table
              className="no-border-last"
              style={{ textAlign: "center", margin: "auto", }}
              scroll={{ x: '100%' }}  // Menambahkan scrolling horizontal
              columns={tableColumns}
              dataSource={data}
              rowKey="id"
              loading={isLoading}
              pagination={{
                defaultPageSize: 10,
                defaultCurrent: metaData.page,
                total: metaData.total_data,
              }}
              onChange={handleTableChange}
            />
          </Card>
        </Col>
      </Row>
      {/* <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Button
            type="primary"
            style={{ border: "0px" }}
            htmlType="submit"
            onClick={() => {
              history.push({
                pathname: `${strings.navigation.path.detail_content}`,
              });
            }}
            block
          >
            Add New Content
          </Button>
        </Col>
      </Row> */}
      {contextHolder}
    </>
  );
};

export default withRouter(CONTENTS);
