import { Col, Row, message, Card } from "antd";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import StatisticWidget from "components/shared-components/StatisticWidget";
import { useDispatch } from "react-redux";
import { Bar } from 'react-chartjs-2';
import { PROVINSI } from "../provinsi";
import { fetchAllVotes } from "redux/features/suara";


export const DefaultDashboard = () => {

  const dispatch = useDispatch();
  const [counts,setContentCount] = useState({})
  const [kota,setKotaCount] = useState(0)
  const [kabupaten,setKabupatenCount] = useState(0)
  const [provinsi,setProvinsiCount] = useState(0)

  const getAllContent = async () => {
    try {
      const response = await dispatch(fetchAllVotes()).unwrap();
      console.log(response.data)
      setContentCount(response.data);
      setProvinsiCount(response.data[2]._sum.suara)
      setKabupatenCount(response.data[0]._sum.suara)
      setKotaCount(response.data[1]._sum.suara)
    } catch (error) {
      message.error(error?.message || "Failed to fetch data");
    }
  };

  useEffect(() => {
    const filters = {
      page: 1,
      limit: 10,
      sort: "created_at",
    };

    getAllContent(filters);
  }, []);

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Dashboard</h2>
          <p>
            Jika menemukan bug ataupun error pada aplikasi ini bisa langsung
          </p>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} key={1}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Total Kabupaten/Kota`}
                value={17}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} key={2}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Total Calon Kepala Daerah`}
                value={46}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} key={3}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Total Suara`}
                value={6382739}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} key={4}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Total Suara Terhitung Provinsi`}
                value={provinsi}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} key={4}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Total Suara Terhitung Kota`}
                value={kota}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} key={4}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Total Suara Terhitung Kabupaten`}
                value={kabupaten}
              />
            </Col>
          </Row>
          <PROVINSI></PROVINSI>
        </Col>
      </Row>
    </>
  );
};

export default withRouter(DefaultDashboard);
