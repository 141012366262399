import { Col, Row, message } from "antd";
import React, { useEffect } from "react";
import { Button, Card, Form, Input } from "antd";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  updateSuara, fetchOneSuara
} from "redux/features/suara";

export const DETAIL_CATEGORY = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const location = useLocation();

  const onFinish = (values) => {
    updateData({
      ...values,
      id: location.state.id,
    });
  };

  const updateData = async (values) => {
    try {
      console.log(values)
      await dispatch(updateSuara({
        id:location?.state?.id,
        suara:parseInt(values.suara)
      })).unwrap();
      history.push("/app/suara-dpc");
    } catch (error) {
      message.error(error?.message || "Failed to fetch data");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const getDataById = async (id) => {
    try {
      const data = await dispatch(fetchOneSuara(id)).unwrap();
      console.log(data)
      form.setFieldsValue({
        ...data.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (location?.state?.id) {
      getDataById(location?.state?.id);
    }
  }, []);

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Update Suara</h2>
          <p>Please fill your data</p>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card>
            <Form
              name="basic"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Calon"
                name="calon"
              >
                <Input disabled />
              </Form.Item>

              <Form.Item
                label="Kabupaten"
                name="kabupaten"
              >
                <Input disabled />
              </Form.Item>

              <Form.Item
                label="Kecamatan"
                name="kecamatan"
              >
                <Input disabled />
              </Form.Item>

              <Form.Item
                label="Suara"
                name="suara"
              >
                <Input />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default DETAIL_CATEGORY;
