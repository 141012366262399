import {
  Button,
  Card,
  Col,
  message,
  Row,
  Table,
  Input,
  Modal,
  Select,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { strings } from "res";
import { fetchAllSuara, deleteSuara } from "redux/features/suara";
import { capitalize, filter } from "lodash";

const LocalizedModal = () => {
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const hideModal = () => {
    setOpen(false);
  };
  return (
    <>
      <Modal
        title="Modal"
        open={open}
        onOk={hideModal}
        onCancel={hideModal}
        okText="Oke"
        cancelText="Cancel"
      >
        <p>Bla bla ...</p>
        <p>Bla bla ...</p>
        <p>Bla bla ...</p>
      </Modal>
    </>
  );
};

export const CONTENTS = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [metaData, setMetaData] = useState({});
  const [totalData, setTotalData] = useState(0);
  const [modal, contextHolder] = Modal.useModal();

  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sort: "created_at",
    total_data: 0,
  });

  const tableColumns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Calon",
      dataIndex: "calon",
      key: "calon",
    },
    {
      title: "Tipe",
      dataIndex: "tipe",
      key: "tipe",
    },
    {
      title: "Kabupaten",
      dataIndex: "kabupaten",
      key: "kabupaten",
    },
    {
      title: "Kecamatan",
      dataIndex: "kecamatan",
      key: "kecamatan",
    },
    {
      title: "Suara",
      dataIndex: "suara",
      key: "suara",
    },
    {
      title: () => <div className="text-center">Detail</div>,
      key: "detail",
      render: (_, record) => (
        <div className="text-center">
          <Button
            type="primary"
            style={{ textAlign: "center" }}
            onClick={() => {
              history.push({
                pathname: `${strings.navigation.path.detail_suara}`,
                state: record,
              });
            }}
          >
            Update Suara
          </Button>
        </div>
      ),
    },
    // {
    //   title: () => <div className="text-center">Action</div>,
    //   key: "status",
    //   render: (_, record) => (
    //     <div className="text-center">
    //       <Button
    //         type="danger"
    //         style={{ textAlign: "center", color: "white" }}
    //         onClick={() => {
    //           confirm(record.id);
    //         }}
    //       >
    //         Delete
    //       </Button>
    //     </div>
    //   ),
    // },
  ];

  const getData = async (params) => {
    try {
      setLoading(true);
      const response = await dispatch(fetchAllSuara(params)).unwrap();
      setMetaData(response.meta.page);
      setData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error?.message || "Failed to fetch data");
    }
  };

  const confirm = (id) => {
    modal.confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure wanna delete this?",
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        await dispatch(deleteSuara(id));
        getData(filters);
      },
      onCancel: () => { },
    });
  };

  const handleSearchByTitle = (event) => {
    setFilters({ ...filters, calon: event.target.value ? event.target.value : undefined });
  }

  const handleSearchByKabupaten = (event) => {
    setFilters({ ...filters, kabupaten: event.target.value ? event.target.value : undefined });
  }

  const handleSearchByKecamatan = (event) => {
    setFilters({ ...filters, kecamatan: event.target.value ? event.target.value : undefined });
  }

  const handleTableChange = (pagination, filter, sorter) => {
    let sorterKey = "";
    
    setFilters({ ...filters, page: pagination.current });

    if (sorter.field === "date_published" && sorter.order) {
      sorterKey = sorter.order === "ascend" ? sorter.field : `-${sorter.field}`;
      setFilters({ ...filters, page: 1, sort: sorterKey, page: pagination.current });
    }

    if (sorter.field === "created_at" && sorter.order) {
      sorterKey = sorter.order === "ascend" ? sorter.field : `-${sorter.field}`;
      setFilters({ ...filters, page: 1, sort: sorterKey, page: pagination.current });
    }

  };

  useEffect(() => {
    getData(filters)
  }, [filters]);

  return (
    <>
      <LocalizedModal></LocalizedModal>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Total Suara</h2>
          <p>Semua suara calon berdasarkan wilayah</p>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card>
            <Row gutter={[16, 2]}>
              <Col xs={8} sm={8} md={8} lg={8} span={6}>
                <Input
                  placeholder="Cari Berdasarkan Nama Calon"
                  onSelect={handleSearchByTitle}
                  className="mb-4 mr-4"
                  allowClear
                  style={{ width: "100%" }}
                />
              </Col>
              <Col xs={8} sm={8} md={8} lg={8} span={6}>
                <Input
                  placeholder="Cari Berdasarkan Kabupaten"
                  onSelect={handleSearchByKabupaten}
                  className="mb-4 mr-4"
                  allowClear
                  style={{ width: "100%" }}
                />
              </Col>
              <Col xs={8} sm={8} md={8} lg={8} span={6}>
                <Input
                  placeholder="Cari Berdasarkan Kecamatan"
                  onSelect={handleSearchByKecamatan}
                  className="mb-4 mr-4"
                  allowClear
                  style={{ width: "100%" }}
                />
              </Col>
              {/* <Col xs={24} sm={24} md={6} lg={6} span={6}>
                <Select
                  placeholder="Filter by category"
                  onSelect={handleCategorySelect}
                  options={categories}
                  className="mb-4 mr-4"
                  allowClear
                  onClear={handleCategorySelect}
                  style={{ width: "100%" }}
                />
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} span={6}>
                <Select
                  placeholder="Filter by subcategory"
                  onSelect={handleSubcategorySelect}
                  options={subcategories}
                  className="mb-4 mr-4"
                  allowClear
                  onClear={handleSubcategorySelect}
                  style={{ width: "100%" }}
                />
              </Col> */}
            </Row>
            <Table
              className="no-border-last"
              style={{ textAlign: "center", margin: "auto", }}
              scroll={{ x: '100%' }}  // Menambahkan scrolling horizontal
              columns={tableColumns}
              dataSource={data}
              rowKey="id"
              loading={isLoading}
              pagination={{
                defaultPageSize: 10,
                defaultCurrent: metaData.page,
                total: metaData.total_data,
              }}
              onChange={handleTableChange}
            />
          </Card>
        </Col>
      </Row>
      {/* <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Button
            type="primary"
            style={{ border: "0px" }}
            htmlType="submit"
            onClick={() => {
              history.push({
                pathname: `${strings.navigation.path.detail_content}`,
              });
            }}
            block
          >
            Add New Content
          </Button>
        </Col>
      </Row> */}
      {contextHolder}
    </>
  );
};

export default withRouter(CONTENTS);
