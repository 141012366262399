import {
  Button,
  Card,
  Col,
  message,
  Row,
  Table,
  Input,
  Modal,
  Select,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { strings } from "res";
import { fetchAllSuara, deleteSuara, fetchAllSuaraKabupaten, fetchAllSuaraGubernur } from "redux/features/suara";
import { Bar } from 'react-chartjs-2';

const data = {
  labels: [],
  datasets: [
    {
      label: 'Suara',
      data: [],
      backgroundColor: 'rgba(75, 192, 192, 0.5)',
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 1,
    },
  ],
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: 'top',
    },
    title: {
      display: true,
      text: 'Suara Pemilih',
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};

const LocalizedModal = () => {
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const hideModal = () => {
    setOpen(false);
  };
  return (
    <>
      <Modal
        title="Modal"
        open={open}
        onOk={hideModal}
        onCancel={hideModal}
        okText="Oke"
        cancelText="Cancel"
      >
        <p>Bla bla ...</p>
        <p>Bla bla ...</p>
        <p>Bla bla ...</p>
      </Modal>
    </>
  );
};

const KABUPATEN_KOTA_SUMATERA_SELATAN = [
  { id: 16, label: "PALEMBANG", value: "PALEMBANG" },
  { id: 17, label: "PRABUMULIH", value: "PRABUMULIH" },
  { id: 1, label: "PAGARALAM", value: "PAGARALAM" },
  { id: 14, label: "LUBUKLINGGAU", value: "LUBUKLINGGAU" }
];

export const CONTENTS = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [totalData, setTotalData] = useState({});
  const [metaData, setMetaData] = useState({});
  const [kabupaten, setKabupaten] = useState("LUBUKLINGGAU");
  const [modal, contextHolder] = Modal.useModal();

  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sort: "created_at",
    total_data: 0,
  });

  const tableColumns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Calon",
      dataIndex: "calon",
      key: "calon",
    },
    {
      title: "Tipe",
      dataIndex: "tipe",
      key: "tipe",
    },
    {
      title: "Kabupaten",
      dataIndex: "kabupaten",
      key: "kabupaten",
    },
    {
      title: "Kecamatan",
      dataIndex: "kecamatan",
      key: "kecamatan",
    },
    {
      title: "Suara",
      dataIndex: "suara",
      key: "suara",
    }
  ];

  const getData = async (params) => {
    try {
      setLoading(true);
      const response = await dispatch(fetchAllSuara({
        ...params,
        tipe: "KOTA"
      })).unwrap();
      const suara = await dispatch(fetchAllSuaraKabupaten({
        kabupaten: kabupaten
      }))
      // console.log(suara.payload.data)
      setTotalData(suara.payload.data)
      setMetaData(response.meta.page);
      setData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error?.message || "Failed to fetch data");
    }
  };

  const confirm = (id) => {
    modal.confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure wanna delete this?",
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        await dispatch(deleteSuara(id));
        getData(filters);
      },
      onCancel: () => { },
    });
  };

  const handleSearchByKabupaten = (event) => {
    setKabupaten(event)
    setFilters({ ...filters, kabupaten: event ? event : undefined });
  }

  const handleTableChange = (pagination, filter, sorter) => {
    let sorterKey = "";

    setFilters({ ...filters, page: pagination.current });

    if (sorter.field === "date_published" && sorter.order) {
      sorterKey = sorter.order === "ascend" ? sorter.field : `-${sorter.field}`;
      setFilters({ ...filters, page: 1, sort: sorterKey, page: pagination.current });
    }

    if (sorter.field === "created_at" && sorter.order) {
      sorterKey = sorter.order === "ascend" ? sorter.field : `-${sorter.field}`;
      setFilters({ ...filters, page: 1, sort: sorterKey, page: pagination.current });
    }

  };

  useEffect(() => {
    getData(filters)
  }, [filters]);

  return (
    <>
      <LocalizedModal></LocalizedModal>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Total Suara Pilkada Kota</h2>
          <p>Semua suara calon berdasarkan wilayah</p>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} key={1}>
          <Card>
            <h3>Suara Total</h3>
            <Bar data={{
              labels: totalData?.names ? totalData?.names : [],
              datasets: [
                {
                  label: 'Suara',
                  data: totalData?.values ? totalData?.values : [],
                  backgroundColor: 'rgba(75, 192, 192, 0.5)',
                  borderColor: 'rgba(75, 192, 192, 1)',
                  borderWidth: 1,
                },
              ],
            }} options={options} />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} key={1}>
          <Card>
            <h3>Per Kecamatan</h3>
            <Bar data={data} options={options} />
          </Card>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card>
            <Row gutter={[16, 2]}>
              <Col xs={24} sm={24} md={24} lg={24} span={6}>
                <Select
                  placeholder="Cari Berdasarkan Kota"
                  onSelect={handleSearchByKabupaten}
                  options={KABUPATEN_KOTA_SUMATERA_SELATAN}
                  className="mb-4 mr-4"
                  allowClear
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
            <Table
              className="no-border-last"
              style={{ textAlign: "center", margin: "auto", }}
              scroll={{ x: '100%' }}  // Menambahkan scrolling horizontal
              columns={tableColumns}
              dataSource={data}
              rowKey="id"
              loading={isLoading}
              pagination={{
                defaultPageSize: 10,
                defaultCurrent: metaData.page,
                total: metaData.total_data,
              }}
              onChange={handleTableChange}
            />
          </Card>
        </Col>
      </Row>
      {contextHolder}
    </>
  );
};

export default withRouter(CONTENTS);
