import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import URLS from 'redux/urls'
import request from 'redux/utils/request'

export const fetchAllSuara = createAsyncThunk(
    'Suara/fetchAllSuara',
    async (params, { rejectWithValue }) => {
        try {
            const response = await request('get', URLS.SUARA, params)
            return response
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

// /api/v1/suara/summary/show-per-candidate-by-kabupaten
export const fetchAllSuaraKabupaten = createAsyncThunk(
    'Suara/fetchAllSuaraKabupaten',
    async (params, { rejectWithValue }) => {
        try {
            const response = await request('get', URLS.SUARA + "/summary/show-per-candidate-by-kabupaten", params)
            return response
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchAllSuaraKabupatenKecamatan = createAsyncThunk(
    'Suara/fetchAllSuaraKabupatenKecamatan',
    async (params, { rejectWithValue }) => {
        try {
            const response = await request('get', URLS.SUARA + "/summary/show-per-candidate-by-kabupaten-kecamatan", params)
            return response
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchAllSuaraGubernur = createAsyncThunk(
    'Suara/fetchAllSuaraGubernur',
    async (_, { rejectWithValue }) => {
        try {
            const response = await request('get', URLS.SUARA + "/summary/show-per-candidate-governor")
            return response
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

// /api/v1/suara/summary/show-total-votes
export const fetchAllVotes = createAsyncThunk(
    'Suara/fetchAllVotes',
    async (_, { rejectWithValue }) => {
        try {
            const response = await request('get', URLS.SUARA + "/summary/show-total-votes")
            return response
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const addSuara = createAsyncThunk(
    'Suara/addSuara',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await request('post', URLS.SUARA, payload)
            return response
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const updateSuara = createAsyncThunk(
    'Suara/updateSuara',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await request('patch', `${URLS.SUARA}/${payload.id}`, payload)
            return response
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)
export const fetchOneSuara = createAsyncThunk(
    'Suara/fetchOneSuara',
    async (id, { rejectWithValue }) => {
        try {
            const response = await request('get', `${URLS.SUARA}/${id}`)
            return response
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const deleteSuara = createAsyncThunk(
    'Suara/deleteSuara',
    async (id, { rejectWithValue }) => {
        try {
            const response = await request('delete', `${URLS.SUARA}/${id}`)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

const initialState = {
    loading: {
        query: false,
        mutation: false
    },
    filter: {
        q: ''
    },
    list: [],
    count: {},
    selected: {},
    selectedRows: []
}

const loadingReducer = (fieldName, status) => (state) => {
    state.loading[fieldName] = status
}

const startLoadingQuery = loadingReducer('query', true)
const stopLoadingQuery = loadingReducer('query', false)
const startLoadingMutation = loadingReducer('mutation', true)
const stopLoadingMutation = loadingReducer('mutation', false)

export const SuaraSlice = createSlice({
    name: 'Suara',
    initialState,
    reducers: {
        setAppliedSearchText: (state, action) => {
            state.filter.q = action.payload
        },
        setSelectedRows: (state, action) => {
            state.selectedRows = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchAllSuara.pending, startLoadingQuery)
            .addCase(fetchAllSuara.fulfilled, (state, action) => {
                state.list = action.payload
                state.loading.query = false
            })
            .addCase(fetchAllSuara.rejected, stopLoadingQuery)
        builder
            .addCase(fetchOneSuara.pending, startLoadingQuery)
            .addCase(fetchOneSuara.rejected, stopLoadingQuery)
            .addCase(fetchOneSuara.fulfilled, (state, action) => {
                state.loading.query = false
                state.selected = action.payload
            })
        builder
            .addCase(updateSuara.pending, startLoadingQuery)
            .addCase(updateSuara.rejected, stopLoadingQuery)
            .addCase(updateSuara.fulfilled, (state, action) => {
                state.loading.query = false
                state.selected = action.payload
            })
        builder
            .addCase(deleteSuara.pending, startLoadingMutation)
            .addCase(deleteSuara.fulfilled, stopLoadingMutation)
            .addCase(deleteSuara.rejected, stopLoadingMutation)
    }
});


export const { setSelectedRows, setAppliedSearchText } = SuaraSlice.actions;

export default SuaraSlice.reducer;