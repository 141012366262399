import { strings } from "res";
import { DashboardIcon, OrderIcon, ReportIcon } from "../assets/svg/icon";
import {
  UserOutlined,
  BranchesOutlined,
  FileOutlined,
  OneToOneOutlined
} from "@ant-design/icons";

import { all_privileges, admin_privileges } from "res/privileges/index";


const dashBoardNavTree = [
  {
    key: "Home",
    path: strings.navigation.path.dashboard,
    title: "Home",
    breadcrumb: false,
    submenu: [
      {
        key: "Dashboard",
        path: strings.navigation.path.dashboard,
        title: "Dashboard",
        icon: DashboardIcon,
        access: all_privileges,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "Provinsi",
        path: strings.navigation.path.provinsi,
        title: "Provinsi",
        icon: ReportIcon,
        breadcrumb: false,
        access: all_privileges,
        submenu: [],
      },
      {
        key: "Kota",
        path: strings.navigation.path.kota,
        title: "Kota",
        icon: FileOutlined,
        access: all_privileges,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "Kabupaten",
        path: strings.navigation.path.kabupaten,
        title: "Kabupaten",
        icon: BranchesOutlined,
        access: all_privileges,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "Update Suara (Admin)",
        path: strings.navigation.path.suara,
        title: "Update Suara (Admin)",
        icon: OneToOneOutlined,
        access: admin_privileges,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "Update Suara (DPC)",
        path: strings.navigation.path.suara_dpc,
        title: "Update Suara (DPC)",
        icon: OrderIcon,
        access: all_privileges,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "Pengguna",
        path: strings.navigation.path.users,
        title: "Pengguna",
        icon: UserOutlined,
        access: admin_privileges,
        breadcrumb: false,
        submenu: [],
      }
    ],
  },
];

// const ControlTree = [{
//   key: "Pengaturan",
//   path: strings.navigation.path.settings,
//   title: "Pengaturan",
//   breadcrumb: false,
//   submenu: [
//     {
//       key: "Pengaturan",
//       path: strings.navigation.path.settings,
//       title: "Pengaturan",
//       icon: SettingIcon,
//       breadcrumb: false,
//       submenu: []
//     }
//   ]
// }]

const navigationConfig = [
  ...dashBoardNavTree,
  // ...ControlTree,
];

export default navigationConfig;
