import React, { Suspense } from "react";
import { Switch, Redirect } from "react-router-dom";
import PrivateRoute from "components/PrivateRoute";
import { strings } from "res";
import Loading from "components/shared-components/Loading";

// Pages
import DASHBOARD from "./dashboard";
import SUARA from "./suara";
import SUARA_DPC from "./suara-dpc";
import PROVINSI from "./provinsi";
import KABUPATEN from "./kabupaten";
import KOTA from "./kota";
import USERS from "./users";
import DETAIL_USER from "./detail-user";
import DETAIL_SUARA from "./detail-suara";

import SETTINGS from "./settings";

export const AppViews = ({ match }) => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <PrivateRoute
          path={`${strings.navigation.path.dashboard}`}
          component={DASHBOARD}
        />
        <PrivateRoute
          path={`${strings.navigation.path.suara}`}
          component={SUARA}
        />
        <PrivateRoute
          path={`${strings.navigation.path.suara_dpc}`}
          component={SUARA_DPC}
        />
        <PrivateRoute
          path={`${strings.navigation.path.kabupaten}`}
          component={KABUPATEN}
        />
        <PrivateRoute
          path={`${strings.navigation.path.kota}`}
          component={KOTA}
        />
        <PrivateRoute
          path={`${strings.navigation.path.provinsi}`}
          component={PROVINSI}
        />
        <PrivateRoute
          path={`${strings.navigation.path.users}`}
          component={USERS}
        />

        {/* DETAIL */}
      
        <PrivateRoute
          path={`${strings.navigation.path.detail_user}`}
          component={DETAIL_USER}
        />
        <PrivateRoute
          path={`${strings.navigation.path.detail_suara}`}
          component={DETAIL_SUARA}
        />

        <PrivateRoute
          path={`${strings.navigation.path.settings}`}
          component={SETTINGS}
        />

        <Redirect
          from={`${match.url}`}
          to={`${strings.navigation.path.dashboard}`}
        />
      </Switch>
    </Suspense>
  );
};

export default AppViews;
